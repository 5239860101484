/* General styling improvements for better visual appeal */
.video-background-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: 'Arial, sans-serif';
    /* Improved font styling */
}

/* Start button wrapper with gradient overlay */
.start-button-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4));
    color: #fff;
    text-align: center;
    z-index: 10;
    background: fixed;
    background-image: url('../assets/start.png');
    /* Background image */
}

.start-button-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../assets/start.png') center center/cover no-repeat;
    z-index: 10;
}

/* Enhanced Start Button Styling with Larger Size and Pulsing Animation */
.start-button {
    /* Increase the button size */
    color: #fff;
    background: linear-gradient(135deg, #ff725f, #f99a9a);
    /* Gradient from pinkish orange to soft peach */
    border: none;
    border-radius: 50px;
    /* Rounded edges for a softer look */
    padding: 2rem 4rem;
    /* Increased padding for a bigger button */
    text-transform: none;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    animation: pulse 2s infinite alternate;
    /* Pulsing animation to attract attention */
}

/* Button hover effect for a more interactive experience */
.start-button:hover {
    background: linear-gradient(135deg, #f99a9a, #ff725f);
    /* Reversed gradient on hover */
    transform: scale(1.1);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.4);
}

/* Button focus and active states */
.start-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 126, 95, 0.5);
    /* Focus ring for accessibility */
}

.start-button:active {
    transform: scale(0.98);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

/* Pulse animation for the button to create visual interest */
@keyframes pulse {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}

.experience-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
}

/* Improved video background */
.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
    pointer-events: none;
    background-color: #000;
    /* Fallback color to avoid white flash */
    opacity: 0;
    /* Initially hidden */
    transition: opacity 0.5s ease-in;
    /* Fade-in effect */
}

/* Move the bouncing arrow to the top of the viewport */
.scroll-container {
    position: absolute;
    top: 20px;
    /* Arrow now at the top */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 1;
}

/* Bouncing arrow animation */
.arrow-container {
    animation: bounce 2s infinite;
}

.arrow-icon {
    font-size: 2rem;
    color: #fff;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(50px);
    }

    60% {
        transform: translateY(25px);
    }
}